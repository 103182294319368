import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";

import firebase from "firebase/compat/app";
import "firebase/database";
import { getDatabase, update, ref, query, onValue } from 'firebase/database';

import {
  Typography,
  Grid,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { default as MList } from "@mui/material/List";
import Switch from "@mui/material/Switch";

import CreateDialog from "./CreateDialog";
import PaneContext from "../../context/PaneContext";
import "@github/time-elements";
import { List, arrayMove } from "react-movable";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const createMarkup = (content) => {
  return { __html: content };
};

const Tricks = () => {
  const { _addPane } = useContext(PaneContext);
  const [tricks, setTricks] = useState({});
  const [filter, setFilter] = useState("");
  const [active, setActive] = useState(true);

  //anytime tricks changes from firebase subscriber
  //recompute the tricksArray to update the UI
  const tricksArray = useMemo(() => {
    if (Object.keys(tricks).length === 0) {
      return [];
    }

    const entryArr = Object.keys(tricks);
    if (tricks.length !== 0) {
      entryArr.sort((a, b) => (tricks[a].order > tricks[b].order ? 1 : -1));
    }
    return entryArr;
  }, [tricks]);

  const filtered = useMemo(() => {
    let newEntries = tricksArray;

    if (active) {
      newEntries = tricksArray
        .map((key) => {
          const entryObj = tricks[key];
          if (entryObj.active === true) {
            return key;
          } else {
            return false;
          }
        })
        .filter((value) => {
          return value !== false;
        });
    }

    if (filter === "") {
      return newEntries;
    } else {
      const filteredArr = newEntries
        .map((key) => {
          const entryObj = tricks[key];
          const dt = new Date(entryObj.createdAt).toString().toLowerCase();

          if (entryObj.title.toLowerCase().indexOf(filter) !== -1) {
            return key;
          } else if (entryObj.content.toLowerCase().indexOf(filter) !== -1) {
            return key;
          } else if (dt.indexOf(filter) !== -1) {
            return key;
          } else {
            return false;
          }
        })
        .filter((value) => {
          return value !== false;
        });

      return filteredArr;
    }
  }, [tricks, filter, active, tricksArray]);

  const updateOrder = useCallback(
    ({ oldIndex, newIndex }) => {
      //Since we can have filters on (active/filter string)
      //we need to look up with the actual start and end
      //indexes are in the primary tricksArray so that we can
      //move them to the proper positions
      const firstKey = filtered[oldIndex];
      const secondKey = filtered[newIndex];

      let firstIndex = tricksArray.findIndex((value) => value === firstKey);
      let secondIndex = tricksArray.findIndex((value) => value === secondKey);

      //use arrayMove to properly re-arrange the ordering
      const newTricks = arrayMove(tricksArray, firstIndex, secondIndex);

      //build a datastructure of firebase paths to trickId/order and assign new value
      let updatedTricks = {};
      newTricks.forEach((val, index) => {
        updatedTricks[`${val}/order`] = index;
      });

      //push the update to firebase
      const db = getDatabase(firebase.apps[0]);
      update(ref(db, 'tricks/'), updatedTricks);
    },
    [tricksArray, filtered]
  );

  useEffect(() => {
    const db = getDatabase(firebase.apps[0]);
    const q = query(ref(db, 'tricks/'));
    const off = onValue(q, (snapshot) => {
      const val = snapshot.val();
      if (val) {
        setTricks(val);
      }
    });

    return () => {
      setTricks([]);
      off();
    };
  }, []);

  return (
    <Grid container direction="column" style={{ padding: "24px" }}>
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          style={{ paddingBottom: "16px" }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item>
                <Typography variant="h5">Tricks</Typography>
              </Grid>
              <Grid item>
                <Tooltip title={"Show/hide inactive"} placement="top" arrow>
                  <Switch
                    checked={active}
                    onChange={() => {
                      setActive(!active);
                    }}
                    name="active"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-around"
            >
              <Grid item>
                <TextField
                  name="filter"
                  variant="outlined"
                  placeholder="Filter..."
                  size="small"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value.toLowerCase());
                  }}
                />
              </Grid>
              <Grid item>
                <CreateDialog />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        {filtered.length !== 0 && <List
          lockVertically={true}
          values={filtered}
          onChange={updateOrder}
          renderList={({ children, props }) => (
            <MList component="div" {...props}>
              {children}
            </MList>
          )}
          renderItem={({ value, props, isDragged }) => {
            const dt = new Date(tricks[value].createdAt);
            return (
              <ListItem
                {...props}
                divider
                button
                disableRipple
                onClick={(e) => {
                  _addPane({
                    paneType: "TrickDrag",
                    menu: "TrickDrag",
                    entry: tricks[value],
                    keyName: value,
                  });
                  e.preventDefault();
                  e.stopPropagation();
                  return true;
                }}
              >
                <ListItemText
                  primary={
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item>
                        <Grid container alignItems="center">
                          <Grid item>
                            <IconButton
                              data-movable-handle
                              tabIndex={-1}
                              style={{
                                cursor: isDragged ? "grabbing" : "grab",
                              }}
                              size="large">
                              <DragHandleIcon />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">
                              <b>{tricks[value].title}</b> (
                              {tricks[value].votes
                                ? tricks[value].votes.upvote || 0
                                : 0}{" "}
                              👍) -
                              {tricks[value].active && (
                                <span style={{ color: "green" }}>
                                  &nbsp; active
                                </span>
                              )}
                              {!tricks[value].active && (
                                <span style={{ color: "red" }}>
                                  &nbsp; inactive
                                </span>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="overline">
                          <relative-time datetime={dt}>
                            Apr 1, 2014 6:30PM
                          </relative-time>
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      style={{
                        border: "1px solid #d3d3d3",
                        padding: "16px",
                      }}
                      component="div"
                    >
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          tricks[value].content
                        )}
                      />
                    </Typography>
                  }
                />
              </ListItem>
            );
          }}
        />}
        {filtered.length === 0 && (
          <>
            <br />
            No Tricks stored!
            <CreateDialog />
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default React.memo(Tricks);
