import React, { useState, useEffect, useCallback } from "react";
import DragPane from "../components/DragPane";

import {
  IconButton,
  Button,
  TextField,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MinimizeIcon from "@mui/icons-material/Minimize";
import CloseIcon from "@mui/icons-material/Close";
import firebase from "firebase/compat/app";
import { getDatabase, ref, query, onValue, update, remove, child } from "firebase/database";

const formatTitle = (item) => {
  let t = "Edit me...";
  if (item.title) {
    const liked = item.votes ? item.votes.upvote || 0 : 0;
    t = `${item.title} (${liked} 👍)`;
  }
  return t;
};

const TrickDrag = (props) => {
  let { _minimize, _close, pane } = props;
  let [content, setContent] = useState("");
  let [title, setTitle] = useState("");
  let [titleError, setTitleError] = useState(false);
  let [contentError, setContentError] = useState(false);
  let [entry, setEntry] = useState({});
  let [notify, setNotify] = useState(true);
  let [active, setActive] = useState(true);

  useEffect(() => {
    const db = getDatabase(firebase.apps[0]);
    const q = query(ref(db, `tricks/${pane.keyName}`));
    const off = onValue(q, (snapshot) => {
      let value = snapshot.val();
      if (value) {
        setContent(value.content);
        setTitle(value.title || "Edit me...");
        setEntry(value);
        setNotify(value.notify);
        setActive(value.active);
      }
    });

    return () => {
      off();
      setContent("");
      setTitle("");
      setTitleError(false);
      setContentError(false);
      setEntry({});
      setNotify(true);
      setActive(true);
    };
  }, [pane.keyName]);

  let deleteTrick = useCallback(() => {
    const db = getDatabase(firebase.apps[0]);
    remove(child(ref(db), `tricks/${pane.keyName}`)).then(
      () => _close(),
      (error) => console.log(error)
    );
  }, [pane, _close]);

  let saveChanges = useCallback(
    () => {
      if (!title) {
        setTitleError(true);
        return;
      }
      if (!content) {
        setContentError(true);
        return;
      }

      let newEntry = entry;
      entry.content = content;
      entry.title = title;
      entry.notify = notify;
      entry.active = active;

      const db = getDatabase(firebase.apps[0]);
      update(child(ref(db), `tricks/${pane.keyName}`), newEntry).then(
        () => _close(),
        (error) => console.log(error)
      );
    },
    [pane, entry, content, title, notify, active, _close]
  );

  return (
    <DragPane {...props} size={{ width: "550px", height: "500px" }}>
      <Grid
        container
        direction="column"
        style={{ height: "100%" }}
        wrap="nowrap"
      >
        <Grid item xs={1} style={{ maxWidth: "100%" }}>
          <Grid
            className="dragMe"
            item
            style={{
              padding: "10px",
              width: "100%",
              borderBottom: "1px solid #d3d3d3",
              cursor: "pointer",
            }}
          >
            <Grid
              container
              xs
              item
              direction="row"
              wrap="nowrap"
              alignItems="center"
            >
              <Grid container justifyContent="flex-start" item xs={11}>
                <Typography variant="h6">{formatTitle(pane.entry)}</Typography>
              </Grid>
              <Grid xs container item justifyContent="flex-end">
                <IconButton onClick={_minimize} size="large">
                  <MinimizeIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid xs container item justifyContent="flex-end">
                <IconButton
                  onClick={() => {
                    _close();
                  }}
                  size="large">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            wrap="nowrap"
            direction="column"
            style={{ height: "100%" }}
          >
            <Grid item xs style={{ overflow: "auto", padding: "16px" }}>
              <Grid container direction="column">
                <Grid item>
                  <TextField
                    error={titleError}
                    fullWidth
                    variant="filled"
                    autoFocus
                    label="Title"
                    name="title"
                    placeholder="What to call this entry.."
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    onKeyDown={async (e) => {
                      if (e.keyCode === 13 && e.metaKey) {
                        saveChanges(e);
                      }
                    }}
                  />
                </Grid>
                <Grid item>&nbsp;</Grid>
                <Grid item>
                  <TextField
                    variant="filled"
                    error={contentError}
                    fullWidth
                    multiline
                    rows={5}
                    label="Content"
                    name="content"
                    placeholder="What the user will see..."
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                    onKeyDown={async (e) => {
                      if (e.keyCode === 13 && e.metaKey) {
                        saveChanges(e);
                      }
                    }}
                  />
                </Grid>
                <Grid item>&nbsp;</Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={notify}
                        onChange={(e) => {
                          setNotify(e.target.checked);
                        }}
                        name="notify"
                        color="primary"
                      />
                    }
                    label="Notify"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        onChange={(e) => {
                          setActive(e.target.checked);
                        }}
                        name="active"
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={1}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{
                borderTop: "1px solid #d3d3d3",
                backgroundColor: "white",
                maxWidth: "100%",
                padding: "8px",
              }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ color: "red" }}
                  onClick={deleteTrick}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs>
                    <Button onClick={_close} color="primary" variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        saveChanges(e);
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DragPane>
  );
};

export default (TrickDrag);
