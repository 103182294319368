import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import "firebase/database";
import firebase from "firebase/compat/app";
import { getDatabase, ref, query, onValue, orderByChild } from "firebase/database";
import PaneContext from "../../context/PaneContext";
import NavContext from "../../context/NavContext";

let FeedbackEntry = ({ entry, keyName }) => {
  const { _addPane } = useContext(PaneContext);
  let dt = new Date(entry.createdAt);

  return (
    <ListItem
      key={keyName}
      divider
      button
      disableRipple
      onClick={() => {
        _addPane({
          paneType: "FeedbackDrag",
          menu: "FeedbackDrag",
          entry: entry,
          keyName: keyName,
        });
      }}
    >
      <ListItemText
        primary={
          <Grid container direction="column" component="span">
            <Grid item component="span">
              <Grid container direction="row" justifyContent="space-between">
                <Grid item component="span">
                  <Grid container direction="row">
                    <Grid item>
                      <Typography variant="body1">
                        {(entry.author && entry.author.email) || "anonymous"}
                      </Typography>
                    </Grid>
                    <Grid item>&nbsp;</Grid>
                    <Grid item>
                      {entry.comments && (
                        <Typography variant="caption">
                          {entry.comments.length === 1 && (
                            <span> ({entry.comments.length} comment)</span>
                          )}
                          {entry.comments.length > 1 && (
                            <span>({entry.comments.length} comments)</span>
                          )}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item component="span">
                  <Typography variant="overline">
                    <relative-time datetime={dt}>
                      Apr 1, 2014 6:30PM
                    </relative-time>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        secondary={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            component="span"
          >
            <Grid item component="span">
              <Typography component="span">{entry.text}</Typography>
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
};

let Feedback = () => {
  let [entries, setEntries] = useState({});
  let [filter, setFilter] = useState("");
  let [filtered, setFiltered] = useState({});
  const { _updateFeedbackReadCount } = useContext(NavContext);

  useEffect(() => {
    _updateFeedbackReadCount(Object.keys(entries).length);
  }, [entries, _updateFeedbackReadCount]);

  useEffect(() => {
    let saveEntryState = (snapshot) => {
      let val = snapshot.val();

      if (val) {
        setEntries(snapshot.val());
      }
    };

    const db = getDatabase(firebase.apps[0]);
    const q = query(ref(db, 'messages/'), orderByChild('createdAt'));
    const off = onValue(q, saveEntryState);

    return () => {
      setEntries([]);
      off();
    };
  }, []);

  return (
    <Grid container direction="column" style={{ padding: "24px" }}>
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          style={{ paddingBottom: "16px" }}
        >
          <Grid item>
            <Typography variant="h5">Feedback</Typography>
          </Grid>
          <Grid item>
            <TextField
              name="filter"
              variant="outlined"
              placeholder="Filter..."
              size="small"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value.toLowerCase());

                var filterArr = Object.keys(entries)
                  .map((keyName) => {
                    let entry = entries[keyName];
                    entry.keyName = keyName;

                    let dt = new Date(entry.createdAt).toString().toLowerCase();

                    if (
                      entry.author.email.toLowerCase().indexOf(filter) !== -1
                    ) {
                      return entry;
                    } else if (
                      entry.text.toLowerCase().indexOf(filter) !== -1
                    ) {
                      return entry;
                    } else if (dt.indexOf(filter) !== -1) {
                      return entry;
                    } else {
                      return false;
                    }
                  })
                  .filter((entry) => {
                    return entry !== false;
                  });

                let filterObj = {};
                filterArr.forEach((entry) => {
                  filterObj[entry.keyName] = entry;
                });
                setFiltered(filterObj);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <List>
          {filter !== "" &&
            Object.keys(filtered)
              .reverse()
              .map((keyName) => {
                let entry = entries[keyName];
                return (
                  <FeedbackEntry
                    entry={entry}
                    keyName={keyName}
                    key={keyName}
                  />
                );
              })}
          {filter === "" &&
            Object.keys(entries)
              .reverse()
              .map((keyName) => {
                let entry = entries[keyName];

                return (
                  <FeedbackEntry
                    entry={entry}
                    keyName={keyName}
                    key={keyName}
                  />
                );
              })}
        </List>
      </Grid>
    </Grid>
  );
};
export default React.memo(Feedback);
