import React from "react";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Logo from "../../images/marquee_logo.png";

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  col: `${PREFIX}-col`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    height: "100%",
    flexGrow: 1,
  },

  [`& .${classes.col}`]: {
    marginTop: "10%",
  }
}));

const NotFound = (props) => {
  const { } = props;

  return (
    <Root className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <table style={{ width: "50%" }}>
          <tbody>
            <tr>
              <td align="center">
                <div>
                  <img src={Logo} alt="Logo" style={{ width: "150px" }} />
                </div>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td align="center">
                <div>
                  <Typography variant="h1" style={{ fontSize: "45px" }}>
                    404 - Page not found
                  </Typography>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Root>
  );
};

export default (NotFound);
