import React from "react";
import { Typography, Grid } from "@mui/material";
import firebase from "firebase/compat/app";
import { getConfig } from "../../config";

const Home = () => {
  let config = getConfig();
  return (
    <div style={{ padding: "24px" }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">
            Hi, {firebase.auth().currentUser.displayName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Welcome to the Marquee administration interface.
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            The icons on the left will allow you modify the{" "}
            {config.projectId === "marquee-backend-dev" ? (
              <b>DEVELOPMENT</b>
            ) : (
              <b>PRODUCTION</b>
            )}
            &nbsp; user experience of Marquee users.
          </Typography>
        </Grid>
        <Grid item>
          <Typography marginBottom={2} variant="h6">Keep taps on what's going on:</Typography>
          <Typography>Backend:</Typography>
          <ul>
            <li>
              <a href="https://datastudio.google.com/reporting/a7913f24-d2bf-42bb-8812-641e774b118c/page/JZQZB">
                <Typography>Marquee Activity (GDS)</Typography>
              </a>
            </li>
            <li>
              <a href={`https://console.firebase.google.com/u/0/project/${config.projectId}/functions/logs?search=&severity=DEBUG`}>
                <Typography>Firebase Function Logs</Typography>
              </a>
            </li>
            <li>
              <a href={`https://console.firebase.google.com/u/0/project/${config.projectId}/functions/health?range=last-24h`}>
                <Typography>Firebase Function Health</Typography>
              </a>
            </li>
          </ul>
          <Typography>Extension (Requires Access to VSCode Marketplace):</Typography>
          <ul>
            <li>
              <a href="https://marketplace.visualstudio.com/manage/publishers/activecove/extensions/marquee/hub?_a=acquisition">
                <Typography>Acquisition</Typography>
              </a>
            </li>
            <li>
              <a href="https://marketplace.visualstudio.com/manage/publishers/activecove/extensions/marquee/hub?_a=review">
                <Typography>Rating &amp; Reviews</Typography>
              </a>
            </li>
            <li>
              <a href="https://marketplace.visualstudio.com/manage/publishers/activecove/extensions/marquee/hub?_a=acquisition">
                <Typography>Q &amp; A</Typography>
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
