import React, { useState, useEffect } from "react";
import {
  Slide,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "firebase/database";
import { getDatabase, ref, push, serverTimestamp, child, update } from "firebase/database";
import firebase from "firebase/compat/app";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let CreateDialog = (props) => {
  const [open, setOpen] = useState(false);
  let [content, setContent] = useState("");
  let [title, setTitle] = useState("");
  let [titleError, setTitleError] = useState(false);
  let [contentError, setContentError] = useState(false);
  let [notify, setNotify] = useState(true);
  let [active, setActive] = useState(true);

  useEffect(() => {
    return () => {
      setContent("");
      setTitle("");
      setNotify(true);
      setActive(true);
    };
  }, [props]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let saveChanges = () => {
    if (!title) {
      setTitleError(true);
      return;
    }
    if (!content) {
      setContentError(true);
      return;
    }

    const db = getDatabase(firebase.apps[0]);
    const newTrickKey = push(child(ref(db), 'tricks')).key;
    const postData = {
      title: title,
      content: content,
      createdAt: serverTimestamp(),
      notify: notify,
      active: active,
    };
    const updates = { [`/tricks/${newTrickKey}`]: postData };
    update(ref(db), updates).then(
      () => handleClose(),
      (err) => console.error(err)
    );
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        color="primary"
        startIcon={<AddIcon />}
        variant="contained"
      >
        Create
      </Button>
      <Dialog
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              <TextField
                error={titleError}
                fullWidth
                autoFocus
                variant="filled"
                label="Title"
                name="title"
                placeholder="What to call this entry.."
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                onKeyDown={async (e) => {
                  if (e.keyCode === 13 && e.metaKey) {
                    saveChanges();
                  }
                }}
              />
            </Grid>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <TextField
                error={contentError}
                variant="filled"
                fullWidth
                multiline
                rows={5}
                label="Content"
                name="content"
                placeholder="What the user will see..."
                value={content}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
                onKeyDown={async (e) => {
                  if (e.keyCode === 13 && e.metaKey) {
                    saveChanges();
                  }
                }}
              />
            </Grid>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notify}
                    onChange={(e) => {
                      setNotify(e.target.checked);
                    }}
                    name="notify"
                    color="primary"
                  />
                }
                label="Notify"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={(e) => {
                      setActive(e.target.checked);
                    }}
                    name="active"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="primary"
          >
            Cancel
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              saveChanges();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateDialog;
