import React, { useState, useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import { Rnd } from "react-rnd";
import PropTypes from "prop-types";
import PaneContext from "../context/PaneContext";
const PREFIX = 'DragPane';

const classes = {
  paneContainer: `${PREFIX}-paneContainer`
};

const StyledRnd = styled(Rnd)(({ theme }) => ({
  [`& .${classes.paneContainer}`]: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #d3d3d3",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1);",
    borderRadius: "4px",
  }
}));

const DragPane = ({ pane, _size, children, ...rest }) => {

  const { stack, _incrementStack } = useContext(PaneContext);

  const [layer, setLayer] = useState(stack + 1);
  const [size, setSize] = useState(
    rest.size || {
      width: window.innerWidth + "px",
      height: window.innerHeight + "px",
    }
  );
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (pane.position) {
      setPosition(pane.position);
    }
    const widthMargin = window.innerWidth * 0.6;
    const heightMargin = window.innerHeight * 0.6;
    const width = Math.max(window.innerWidth - widthMargin, 800);
    const height = Math.max(window.innerHeight - heightMargin, 550);
    setSize({ width: `${width}px`, height: `${height}px` });
    setPosition({
      x: (window.innerWidth / 2) - (width / 2),
      y: window.innerHeight * 0.1,
    });
  }, [pane.position]);

  return (
    <StyledRnd
      dragHandleClassName={"dragMe"}
      bounds={".appContainer"}
      dragGrid={[10, 10]}
      resizeGrid={[10, 10]}
      style={{ zIndex: layer * 10 }}
      size={size}
      position={position}
      minWidth={200}
      minHeight={200}
      enableResizing={{ bottomRight: true }}
      onResizeStop={(e, direction, ref) => {
        let currSize = { width: ref.style.width, height: ref.style.height };
        setSize(currSize);
        if (_size) {
          _size(currSize);
        }
      }}
      onDragStop={(e, d) => {
        let currPos = { x: d.x, y: d.y };
        setPosition(currPos);
      }}
      onClick={() => {
        _incrementStack();
        setLayer(stack);
      }}
    >
      <Box className={classes.paneContainer}>
        <span>{children}</span>
      </Box>
    </StyledRnd>
  );
};

DragPane.propTypes = {
  pane: PropTypes.object.isRequired,
};

export default React.memo(DragPane);
