import React from "react";
import { styled } from '@mui/material/styles';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CreateIcon from "@mui/icons-material/Create";

const PREFIX = 'PaneMenu';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.paper}`]: {
    border: "1px solid #d3d4d5",
    color: "black",
  },
});

const StyledMenu = ((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const PaneMenu = (props) => {
  const { items, children } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  }

  function handleClose(event) {
    setAnchorEl(null);
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  return (
    (<Root>
      <IconButton size="small" color="inherit" onClick={handleClick}>
        {children}
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.paper
        }}>
        {items.map((item) => {
          return (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (item.actionFunc) {
                  item.actionFunc();
                }
                handleClose();
              }}
              key={item.id}
              dense={true}
            >
              {item.icon && (
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography
                    noWrap
                    variant="body2"
                    style={{ maxWidth: "200px" }}
                  >
                    {item.primary || ""}
                  </Typography>
                }
                secondary={item.secondary || ""}
              />
            </MenuItem>
          );
        })}
      </StyledMenu>
    </Root>)
  );
};

PaneMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default PaneMenu;
