const config = {
  default: {
    apiKey: "AIzaSyBgF-4aAEgxT2lHY1sV9Unv3FAwiXk0tR8",
    authDomain: "marquee-backend-dev.firebaseapp.com",
    databaseURL: "https://marquee-backend-dev.firebaseio.com",
    projectId: "marquee-backend-dev",
    storageBucket: "marquee-backend-dev.appspot.com",
    messagingSenderId: "440351587252",
    appId: "1:440351587252:web:4251fedb953608310550e1",
  },
  prod: {
    apiKey: "AIzaSyCsv6JxJ5naEixQgeEiNLup_HpYGM9_Hlo",
    authDomain: "marquee-backend.firebaseapp.com",
    databaseURL: "https://marquee-backend.firebaseio.com",
    projectId: "marquee-backend",
    storageBucket: "marquee-backend.appspot.com",
    messagingSenderId: "569394879893",
    appId: "1:569394879893:web:8d6ca91163a57dd1616a9c",
    measurementId: "G-B5TMR8H2RB",
  },
};

export function getConfig() {
  if (window.location.hostname.indexOf("admin.marquee.activecove.com") > -1) {
    return config.prod;
  }
  return config.default;
}
