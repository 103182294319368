import React, { useContext } from "react";
import { styled } from '@mui/material/styles';
import PaneContext from "./context/PaneContext";

import TrickDrag from "./panes/TrickDrag";
import FeedbackDrag from "./panes/FeedbackDrag";

const PREFIX = 'PaneLayers';

const classes = {
  dragBox: `${PREFIX}-dragBox`
};

const Root = styled('div')(() => ({
  [`&.${classes.dragBox}`]: {
    position: "absolute",
  }
}));

let components = {
  TrickDrag: <TrickDrag />,
  FeedbackDrag: <FeedbackDrag />,
};

const PaneLayers = () => {
  const { panes, _removePane, _updatePaneMinimize } = useContext(PaneContext);

  return (
    <Root className={classes.dragBox}>
      {panes.map((pane, index) => {
        if (!pane.minimized) {
          let DialogComponent = components[pane.paneType];
          return React.cloneElement(DialogComponent, {
            _close: () => _removePane(pane.id),
            _minimize: () => _updatePaneMinimize(pane, true),
            id: pane.id,
            pane: pane,
            key: pane.type + "_" + pane.id,
            index: index,
          });
        } else {
          return true;
        }
      })}
    </Root>
  );
};

PaneLayers.propTypes = {};

export default (PaneLayers);
