import React, { useState, useEffect, useContext } from "react";
import PaneContext from "../context/PaneContext";
import Badge from "@mui/material/Badge";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import PaneMenu from "./PaneMenu";

const PaneBadge = () => {
  const { panes, _updatePaneMinimize } = useContext(PaneContext);

  const [menuitems, setMenuitems] = useState([]);

  useEffect(() => {
    let menuitems = [];
    panes.map((pane) => {
      if (pane.minimized) {
        let identifier = pane.paneType;
        let item = {
          id: pane.id,
          primary: pane.menu || identifier,
          icon: "info",
          actionFunc: () => {
            _updatePaneMinimize(pane, false);
          },
        };
        menuitems.push(item);
      }
      return true;
    });

    setMenuitems(menuitems);
  }, [panes, _updatePaneMinimize]);

  return (
    <>
      {menuitems.length !== 0 && (
        <PaneMenu items={menuitems}>
          <Badge badgeContent={menuitems.length} color="primary">
            <DynamicFeedIcon fontSize="small" />
          </Badge>
        </PaneMenu>
      )}
    </>
  );
};

export default React.memo(PaneBadge);
