import React from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoIcon from "../images/marquee_logo.png";
import Logo from "../images/marquee_logo.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import NavBar from "../components/NavBar";
import { PaneProvider } from "../context/PaneContext";
import { NavProvider } from "../context/NavContext";

import PaneLayers from "../PaneLayers";

const PREFIX = 'Base';

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: 36,
  },

  [`& .${classes.hide}`]: {
    display: "none",
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },

  [`& .${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.drawerClose}`]: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },

  [`& .${classes.toolbar}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },

  [`& .${classes.content}`]: {
    // border: "1px solid red",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#fff",
    //flexGrow: 1
    // padding: theme.spacing(3)
  }
}));

const drawerWidth = 240;

const Base = (props) => {

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Root className={classes.root}>
      <NavProvider>
        <PaneProvider>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              {open ? (
                <img src={Logo} alt="Marquee" style={{ height: "50px" }} />
              ) : (
                <img src={LogoIcon} alt="Marquee" style={{ height: "40px" }} />
              )}
            </div>
            <Divider />

            <NavBar />
            {/* {!open && <Divider />} */}
            <List
              style={{
                position: "absolute",
                bottom: "0px",
                width: "100%",
              }}
            >
              {!open && (
                <ListItem button onClick={handleDrawerOpen}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </ListItem>
              )}
              {open && (
                <ListItem button onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ChevronLeftIcon />
                  </ListItemIcon>
                </ListItem>
              )}
            </List>
          </Drawer>
          {/* <ModalProvider rootComponent={TransitionGroup}> */}
          {/* <AvatarMenu /> */}
          <PaneLayers />

          <main className={`appContainer ${classes.content}`}>
            {props.children}
          </main>
          {/* </ModalProvider> */}
        </PaneProvider>
      </NavProvider>
    </Root>
  );
};

export default React.memo(Base);
