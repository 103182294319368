import React, { createContext, useState, useEffect, useMemo } from "react";
import "firebase/database";
import firebase from "firebase/compat/app";
import { getDatabase, ref, query, orderByChild, onValue } from "firebase/database";
import * as store from "store2";

const NavContext = createContext();

const NavProvider = ({ children }) => {
  const NavStore = store.namespace("nav");

  const [feedbackReadCount, setFeedbackReadCount] = useState(0);
  const [entries, setEntries] = useState({});

  const feedbackCount = useMemo(() => {
    return Object.keys(entries).length;
  }, [entries]);

  let _updateFeedbackReadCount = (count) => {
    NavStore.set("feedbackReadCount", count);
    setFeedbackReadCount(count);
  };

  useEffect(() => {
    setFeedbackReadCount(NavStore.get("feedbackReadCount", 0));

    let saveEntryState = (snapshot) => {
      let val = snapshot.val();
      if (val) {
        setEntries(snapshot.val());
      }
    };

    const db = getDatabase(firebase.apps[0]);
    const topUserPostsRef = query(ref(db, 'messages/'), orderByChild('createdAt'));
    const off = onValue(topUserPostsRef, saveEntryState);

    return () => {
      setFeedbackReadCount(0);
      setEntries([]);
      off();
    };
  }, []);

  return (
    <NavContext.Provider
      value={{
        feedbackReadCount,
        feedbackCount,
        _updateFeedbackReadCount,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export default NavContext;
export { NavProvider };
