import { createTheme } from "@mui/material/styles";
import "typeface-lato";

const theme = createTheme({
  palette: {
    background: {
      default: "#fff"
    },
    primary: {
      main: "#4998c9"
    },
    secondary: {
      main: "#e34d73"
    },
    error: {
      light: "#F09C9B", // Faded Red
      main: "#F44638" // Bright Red
    },
    text: {
      primary: "#323439", // Off Black
      secondary: "#818181" // Dark Grey
    }
  },
  typography: {
    fontFamily: ["lato", "Roboto", "sans-serif"].join(","),
    fontSize: 14,
    button: {
      textTransform: "none",
      minHeight: "35px"
    }
  },
  overrides: {
    MuiTab: {
      root: {
        "&$selected": { backgroundColor: "#F7F7F7" },
        // backgroundColor: "#ffffff"
        "&:hover": {
          backgroundColor: "#D1D1D1",
          opacity: 1
        }
      },
      wrapper: { alignItems: "left" }
    }
  }
});

export default theme;
