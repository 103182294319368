import React, { useState, useEffect, useRef, useCallback } from "react";
import DragPane from "../components/DragPane";

import {
  IconButton,
  Button,
  TextField,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import MinimizeIcon from "@mui/icons-material/Minimize";
import CloseIcon from "@mui/icons-material/Close";
import firebase from "firebase/compat/app";
import { getDatabase, ref, query, onValue, set, remove, child, serverTimestamp } from "firebase/database";
import "@github/time-elements";
import copy from "copy-to-clipboard";

let CommentInput = (props) => {
  let [comment, setComment] = useState("");
  let commentInput = useRef();

  const addComment = useCallback(
    (comment) => {
      let newComment = {
        body: comment,
        email: firebase.auth().currentUser.email,
        createdAt: serverTimestamp(),
      };
      let newEntry = props.entry;
      if (!newEntry.comments) {
        newEntry.comments = [];
      }
      newEntry.comments.push(newComment);

      const db = getDatabase(firebase.apps[0]);
      set(child(ref(db), `messages/${props.keyName}`), newEntry).then(
        () => {
          setComment("");
          commentInput.current.focus();
        },
        (error) => console.log(error)
      );
    },
    [props.entry, props.keyName]
  );

  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item xs={10}>
        <TextField
          inputRef={commentInput}
          autoFocus
          multiline
          maxRows={5}
          rows={2}
          label="Comment"
          variant="filled"
          fullWidth
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          onKeyDown={async (e) => {
            if (e.keyCode === 13 && e.metaKey) {
              addComment(comment);
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            addComment(comment);
          }}
        >
          Post
        </Button>
      </Grid>
    </Grid>
  );
};

let CommentThread = (props) => {
  return <>
    <CommentInput {...props} />
    <Grid item>&nbsp;</Grid>
    <Grid item>
      <Grid container direction="row">
        <Grid item xs={1}>
          &nbsp;
        </Grid>

        <Grid item xs>
          {!props.entry.comments && (
            <Typography>No comments yet...</Typography>
          )}
          {props.entry.comments &&
            props.entry.comments
              .sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((comment) => {
                return (
                  <Grid
                    key={comment.createdAt}
                    container
                    style={{
                      border: "1px solid #d3d3d3",
                      padding: "16px",
                      marginTop: "8px",
                    }}
                    direction="column"
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>{comment.email}</Grid>
                        <Grid item>
                          <relative-time
                            datetime={new Date(comment.createdAt)}
                          >
                            Apr 1, 2014 6:30PM
                          </relative-time>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>{comment.body}</Grid>
                  </Grid>
                );
              })}
        </Grid>
      </Grid>
    </Grid>
  </>;
};

const FeedbackDrag = (props) => {
  let { _minimize, _close, pane } = props;
  let [entry, setEntry] = useState({});

  useEffect(() => {
    const db = getDatabase(firebase.apps[0]);
    const q = query(ref(db, `messages/${pane.keyName}`));
    const off = onValue(q, (snapshot) => {
      let value = snapshot.val();
      if (value) {
        setEntry(value);
      }
    });

    return () => off();
  }, [pane.keyName]);

  const deleteMessage = useCallback(() => {
    const db = getDatabase(firebase.apps[0]);
    remove(child(ref(db), `messages/${pane.keyName}`)).then(
      () => _close(),
      (error) => console.log(error)
    );
  }, [pane, _close]);

  return (
    <DragPane {...props}>
      <Grid
        container
        direction="column"
        style={{ height: "100%" }}
        wrap="nowrap"
      >
        <Grid item xs={1} style={{ maxWidth: "100%" }}>
          <Grid
            className="dragMe"
            item
            style={{
              padding: "10px",
              width: "100%",
              borderBottom: "1px solid #d3d3d3",
              cursor: "pointer",
            }}
          >
            <Grid
              container
              xs
              item
              direction="row"
              wrap="nowrap"
              alignItems="center"
            >
              <Grid container justifyContent="flex-start" item xs={11}>
                <Typography
                  variant="h6"
                  onClick={(e) => {
                    if (e.detail !== 2) {
                      return;
                    }

                    if (entry.author && entry.author.email) {
                      copy(entry.author.email);
                      alert("email copied");
                    }
                  }}
                >
                  {(entry.author && entry.author.email) || "anonymous"}
                </Typography>
              </Grid>
              <Grid xs container item justifyContent="flex-end">
                <IconButton onClick={_minimize} size="large">
                  <MinimizeIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid xs container item justifyContent="flex-end">
                <IconButton
                  onClick={() => {
                    _close();
                  }}
                  size="large">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            wrap="nowrap"
            direction="column"
            style={{ height: "100%" }}
          >
            <Grid item xs style={{ overflow: "auto", padding: "16px" }}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h6">Feedback</Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="overline">
                        <relative-time datetime={new Date(entry.createdAt)}>
                          Apr 1, 2014 6:30PM
                        </relative-time>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>&nbsp;</Grid>
                <Grid item>
                  <Typography variant="body1">{entry.text}</Typography>
                </Grid>
                <Grid item>&nbsp;</Grid>

                <Grid item>
                  <Typography variant="h6">Thread</Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>&nbsp;</Grid>
                <Grid item>
                  <CommentThread entry={entry} keyName={pane.keyName} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={1}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              style={{
                borderTop: "1px solid #d3d3d3",
                backgroundColor: "white",
                maxWidth: "100%",
                padding: "16px",
              }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ color: "red" }}
                  onClick={deleteMessage}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={_close} color="primary" variant="contained">
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DragPane>
  );
};

export default (FeedbackDrag);
