import React, { createContext, useState, useEffect } from "react";

const PaneContext = createContext();

let PaneProvider = (props) => {
  let [panes, setPanes] = useState([]);
  let [stack, setStack] = useState(5);

  let _removePane = (id) => {
    setPanes(panes.filter((pane) => pane.id !== id));
  };

  let _addPane = (pane) => {
    pane.id = Math.random().toString(20).substring(2, Infinity);
    setPanes((prevPanes) => [...prevPanes, pane]);
  };

  let _updatePaneMinimize = (openPane, minimized) => {
    setPanes((prevPanes) =>
      prevPanes.map((pane) =>
        pane.id === openPane.id ? { ...pane, minimized: minimized } : pane
      )
    );
  };

  let _updatePanePosition = (openPane, position) => {
    setPanes((prevPanes) =>
      prevPanes.map((pane) =>
        pane.id === openPane.id ? { ...pane, position: position } : pane
      )
    );
  };

  let _updatePaneSize = (openPane, size) => {
    setPanes((prevPanes) =>
      prevPanes.map((pane) =>
        pane.id === openPane.id ? { ...pane, size: size } : pane
      )
    );
  };

  let _incrementStack = () => {
    setStack((prevStack) => prevStack + 1);
  };

  useEffect(() => {
    return () => {
      setPanes([]);
    };
  }, []);

  return (
    <PaneContext.Provider
      value={{
        panes: panes,
        stack: stack,
        _incrementStack: _incrementStack,
        _removePane: _removePane,
        _addPane: _addPane,
        _updatePanePosition: _updatePanePosition,
        _updatePaneSize: _updatePaneSize,
        _updatePaneMinimize: _updatePaneMinimize,
      }}
    >
      {props.children}
    </PaneContext.Provider>
  );
};

export { PaneProvider };
export default PaneContext;
