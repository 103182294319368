import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import BathtubIcon from "@mui/icons-material/Bathtub";
import Badge from "@mui/material/Badge";
import firebase from "firebase/compat/app";
import PaneBadge from "./PaneBadge";
import PaneContext from "../context/PaneContext";
import NavContext from "../context/NavContext";

const NavBar = () => {
  const { panes } = useContext(PaneContext);
  const { feedbackReadCount, feedbackCount } = useContext(NavContext);

  const unread = useMemo(() => {
    return feedbackCount - feedbackReadCount;
  }, [feedbackReadCount, feedbackCount]);

  const minimizedPanes = useMemo(() => {
    return panes.filter((pane) => pane.minimized === true);
  }, [panes]);

  return (
    <div>
      <ListItem
        selected={window.location.pathname === "/"}
        button
        to={"/"}
        component={Link}
      >
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem
        selected={window.location.pathname === "/tricks"}
        button
        to={"/tricks"}
        component={Link}
      >
        <ListItemIcon>
          <SettingsVoiceIcon />
        </ListItemIcon>
        <ListItemText primary="News" />
      </ListItem>

      <ListItem
        selected={window.location.pathname.indexOf("/feedback") !== -1}
        button
        to={"/feedback"}
        component={Link}
      >
        <ListItemIcon>
          {unread > 0 ? (
            <Badge badgeContent={unread} color="secondary">
              <BathtubIcon />
            </Badge>
          ) : (
            <BathtubIcon />
          )}
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </ListItem>

      <ListItem
        button
        onClick={async () => {
          await firebase.app().auth().signOut();
        }}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
      {minimizedPanes.length > 0 && (
        <ListItem>
          <ListItemIcon>
            <PaneBadge />
          </ListItemIcon>
          <ListItemText primary="Panes" />
        </ListItem>
      )}
    </div>
  );
};

export default NavBar;
