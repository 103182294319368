import * as React from "react";
import { render } from "react-dom";
import {
  Route,
  Routes,
  useParams,
  BrowserRouter as Router,
} from "react-router-dom";

import {
  FirebaseAuthProvider,
  IfFirebaseAuthed,
  IfFirebaseUnAuthed,
} from "@react-firebase/auth";
import { FirebaseDatabaseProvider } from "@react-firebase/database";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { State } from "react-powerplug";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Base from "./layouts/Base";
import Welcome from "./pages/welcome";
import Home from "./pages/home";
import Tricks from "./pages/tricks";
import Feedback from "./pages/feedback";
import NotFound from "./pages/notfound";
import { getConfig } from "./config";
import { ModalProvider } from "react-modal-hook";
import { TransitionGroup } from "react-transition-group";

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  const params = useParams();
  return (
    <Base title={rest.title} nav={rest.nav}>
      <Component {...params} />
    </Base>
  );
};

const App = () => {
  return (
    <div>
      <FirebaseAuthProvider {...getConfig()} firebase={firebase}>
        <State initial={{ isLoading: false }}>
          {() => (
            <React.Fragment>
              <IfFirebaseAuthed>
                <FirebaseDatabaseProvider firebase={firebase} {...getConfig()}>
                  <Router>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={theme}>
                        <ModalProvider rootComponent={TransitionGroup}>
                          <CssBaseline />
                          <Routes>
                            <Route
                              path="/"
                              exact
                              element={<AppLayoutRoute component={Home} />}
                            />
                            <Route
                              path="/feedback"
                              element={<AppLayoutRoute component={Feedback} />}
                            />
                            <Route
                              path="/tricks"
                              element={<AppLayoutRoute component={Tricks} />}
                            />
                            <Route path="*" component={NotFound} />
                          </Routes>
                        </ModalProvider>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </Router>
                </FirebaseDatabaseProvider>
              </IfFirebaseAuthed>
              <IfFirebaseUnAuthed>
                <Welcome />
              </IfFirebaseUnAuthed>
            </React.Fragment>
          )}
        </State>
      </FirebaseAuthProvider>
    </div>
  );
};

render(<App />, document.getElementById("root"));
